import React from "react";
import { CgPentagonDown } from "react-icons/cg";
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { BsWindowDesktop } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const JobCard = ({ data }) => {
  const navigate = useNavigate();
  const applyNow = () => {
    navigate("/apply-now", { state: { data } });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="card border-0 shadow bg-p cardRadi">
        <div className="card-body text-secondary p-4">
          <div className="row">
            <div className="col-md-10">
              <h5 className="fw-bold">{data?.title}</h5>
            </div>
            <div className="col-md-2">
              <CgPentagonDown size={30} className="float-end" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <BsWindowDesktop />
              <span className="text-secondary mx-2">{data?.experence}</span>
            </div>
            <div className="col-md-6">
              <MdLocationPin />
              <span className="text-secondary mx-2">{data?.location}</span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <BsWindowDesktop />
              <span className="text-secondary mx-2">{data?.postUpdatedAt}</span>
            </div>
            {/* <div className="col-md-6">
              <AiOutlineFileText />
              <span className="text-secondary mx-2">
                {data?.JobApplication}
              </span>
            </div> */}
          </div>
          <h5 className="fw-bold mt-4">Share</h5>
          <span className="mx-2">
            <FaLinkedin />
          </span>
          <span className="mx-2">
            <MdEmail />
          </span>
          <span className="mx-2">
            <AiFillTwitterCircle />
          </span>
          <span className="mx-2">
            <RiWhatsappFill />
          </span>

          <h6 className="fw-bold float-end mt-4 linkHighlight" onClick={applyNow}>
            Apply Now
            <span
              style={{ fontSize: "25px", marginTop: "5%" }}
              className="ProText_green"
            >
              &#8594;
            </span>
          </h6>
        </div>
      </div>
    </>
  );
};
