import React from "react";
import m1 from "../Media/Images/misson.png";
import v1 from "../Media/Images/vision.png";
import v2 from "../Media/Images/values.png";
import aboutus from "../Media/Images/aboutUs.svg";
import strips from "../components/StripDesign";

export const AboutUS = () => {
  return (
    <>
      <div className="fade-in-page">
        <div class="card mb-3 border-0 allMaringTop ">
          <img src={aboutus} class="card-img-top" alt="..." />
        </div>
        <div className="col-md-10 mx-auto mt-5 pt-5 ">
          <div className="p-4 text-center pt-5">
            <h1 className="text-secondary fw-bold">DO GOOD BE GOOD</h1>
            <div>{strips.StripDesign3}</div>
          </div>

          <div class="card border-0 card-Style bg-transparent card2  p-1" data-aos="fade-right">
            <div className="row ">
              <div className="col-md-4">
                <div className="card bg-transparent border-0 ">
                  <div class="card-body p-0">
                    <img src={m1} height="75%" width="75%" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div class="card-body mx-auto mt-5 ">
                  <h3>
                    <strong> Mission</strong>
                  </h3>
                  <p>
                    Our mission is to keep People @Core, enabling them and to
                    continuously deliver ‘The Good’ while remaining latest with
                    the insurance industry, underlying technology and Guidewire
                    trends. Our goal is to have happy people all around be it
                    our employees, service providers, partners, clients or end
                    consumers.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card border-0 card-Style bg-transparent card2  p-1 mt-4" data-aos="fade-left">
            <div className="row">
              <div className="col-md-8">
                <div class="card-body mx-auto mt-5 ">
                  <h3>
                    <strong>Vision</strong>
                  </h3>
                  <p>
                    Our Vision is to let our customer experience certainty
                    through our team of ProtecoNINs. We don’t just say it but we
                    do it. Our aim is to build a team of Niche in the Niche who
                    are driven by core human values focused on delivering the
                    client centric solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card bg-transparent border-0 ">
                  <div class="card-body p-0">
                    <img src={v1} height="75%" width="75%" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card border-0 card-Style bg-transparent card2  p-1 mt-4" data-aos="fade-right">
            <div className="row">
              <div className="col-md-4">
                <div className="card bg-transparent border-0 ">
                  <div class="card-body p-0">
                    <img src={v2} height="75%" width="75%" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div class="card-body mx-auto mt-5 ">
                  <h3>
                    <strong>Values</strong>
                  </h3>
                  <p>
                    Our values are driven through the DNA which is "Defined,
                    Nurtured and Achieved" in our people and we live at every
                    moment. It is our DNA that allows us to continuously and
                    repeatedly do ‘The Good’ and help us achieve the vision.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
