/* eslint-disable no-loop-func */
import React, { useState } from "react";
import { Caurousel } from "../components/Caurousel";
import { BiSearchAlt } from "react-icons/bi";
import { JobCard } from "../components/JobCard";
import { TestimoniesCard } from "../components/TestimoniesCard";
import strips from "../components/StripDesign";
import apsara from "../Media/Images/Apsara 1.svg";
import hr3 from "../Media/Images/hr1.svg";
import hr1 from "../Media/Images/hr2.svg";
import hr2 from "../Media/Images/tanu-recrutor.svg";
import {ImLinkedin} from "react-icons/im"

import { BsArrowRight } from "react-icons/bs";
import image from "../Media/Images/NewHights.svg";
import viney from "../Media/Images/Viney.svg";
import { Link } from "react-router-dom";
export const JobApplication = () => {
  const [dropDownData, setDropdownData] = useState({
    key_Skills: "Key Skills",
    select_Country: "Select Country",
    experience: "Experience",
  });

  const [modalData, setModalData] = useState({});

  const data4 = [
    {
      title: "Guidewire Configuration Developer ",
      experence: "3 - 5 years ",
      location: " Hybrid ",
      postUpdatedAt: "1 day ago",
      JobApplication: "0",
      jobId: "23456",
      jobResponsebilities: `<ul>
      <li>
        Utilizing the Azure ecosystem and Databricks platform, create,
        build, and maintain data engineering solutions
      </li>
      <li>
        Build scalable and effective data pipelines, perform data
        transformation and integration, and enable sophisticated analytics
        and machine learning workflows while collaborating closely with
        cross-functional teams that include data scientists, analysts, and
        software engineers
      </li>
      <li>
        Utilize your knowledge of Databricks and Azure services to support
        business intelligence efforts and deliver data-driven insights
      </li>
      <li>
        On the Azure platform with Databricks, help to design, create, and
        deploy end-to-end data engineering solutions
      </li>
      <li>
        With the help of stakeholders, collect and interpret business
        requirements into technical data engineering solutions
      </li>
      <li>
        Execute data ingestion operations, which involve gathering data
        from diverse sources, processing it to suit business requirements,
        and feeding it into data lakes or data warehouses
      </li>
      <li>
        Utilizing Azure Data Factory, Databricks, and other pertinent
        tools, create and maintain scalable data pipelines and workflows
      </li>
      <li>
        For effective data storage and retrieval, create and improve data
        models and schemas
      </li>
      <li>
        Implement security measures, and make sure all legal and
        regulatory standards are met regarding data protection
      </li>
    </ul>`,
      jobRequirments: `<ul>
    <li>
    Bachelor’s/Master’s degree in Engineering, Computer Science (or equivalent experience)
    </li>
    <li>
    At least 5-8+ years of relevant experience as a data engineer
    </li>
    <li>
    Prolific experience working with Azure and Databricksts
    </li>
    <li>
    In-depth knowledge of the principles, procedures, and best practices of data engineering
    </li>
    <li>
    Demonstrable experience leveraging Azure Data Factory, Databricks, and other pertinent technologies to develop and implement data pipelines
    </li>
    <li>
    Proficient knowledge of programming languages like Python, SQL, and Scala
    </li>
    <li>
    Thorough knowledge of data processing and storage technologies like Apache Spark, Azure Data Lake Storage, and Azure SQL Data Warehouse
    </li>
    <li>
    Familiarity with ETL/ELT procedures, schema design, and data modeling
    </li>
    <li>
    Proven problem-solving and troubleshooting abilities, including the ability to recognize and fix data-related problems
    </li>
    <li>
    Outstanding teamwork and communication abilities to work well with cross-functional teams.
    </li>

    <li>
    Data engineering-related Azure certificates or Databricks certifications are nice to have
    </li>

    <li>
    Azure credentials in Machine Learning or DevOps are desirable
    </li>
    <li>
    Prior remote project management experience is crucial
    </li>
    <li>
    Fluent in spoken and written English communication
    </li>
  </ul>
    `,
    },
    {
      title: "Guidewire Datahub Developer ",
      experence: "3 - 5 years ",
      location: " Hybrid ",
      postUpdatedAt: "1 day ago",
      JobApplication: "0",
      jobId: "23457",
      jobResponsebilities: `<ul>
      <li >
      Work directly with the client gathering requirements to align technology with business strategy and goals
      </li>
      <li>
      Assess the impacts on technical design because of the changes in functional requirements
      </li>
      <li>
      Need very strong experienced person in any RDBMS (Oracle / SQL Server)
      </li>
      <li>
      Very strong working experience on SAP BODS to perform data extraction from GW PC/BC/CC and transform in the Guidewire Datahub Layer
      </li>
      <li>
      Use DataHub as the data source and build analytical applications on Guidewire InfoCenter
      </li>`,
      jobRequirments: `<ul>
    <li>
    Minimum 3 yrs exp on Guidewire DataHub Development
    </li>
    <li>
    Ability to engage different stake holders to resolve issues
    </li>
    <li>
    Ability to understand Insurance Policy & Claims life cycle and develop effective data driven solutions sourcing data from Guidewire PC/BC/CC
    </li>
    <li>
    Must have good knowledge of Guidewire Data Model
    </li>
    <li>
    Experience developing ETL solutions using Guidewire DataHub
    </li>
    <li>
    Ability to troubleshoot the SAP BODS scripts and SQL queries/stored procedures
    </li>
    <li>
    Experience in building reports and analytic solutions using Guidewire Infocenter
    </li>
    <li>
    Experience with design and development of data warehouse environments including data marts
    </li>
    `,
    },
    {
      title:
        "Azure Data Engineer (Databricks/DataLake/Data Factory Guidewire) ",
      experence: "5 - 9 years",
      location: " Remote ",
      postUpdatedAt: "1 day ago",
      JobApplication: "0",
      jobId: "23458",
      jobResponsebilities: `<ul>
      <li >
      Design and implement data engineering solutions in Azure Environment with data being read from Parquet files and transformed to multiformat files and table formats to be stored in S3 or passed to downstream applications on SAP, SAS platforms etc.
      </li>
      <li>
      Design and implement batch and streaming solutions to support near real-time analytics.
      </li>
      <li>
      	Develop data models based on the source systems - will be responsible for Work effort estimation and application solutioning.
      </li>
      <li>
    	Troubleshoot productions issues and bug fixes.
      </li>
      <li>
      Translate business requirements into technical specs.
      </li>
      <li>
      	Hands-on developer as part of a global team and coach fellow developers.
      </li>
      `,
      jobRequirments: `<ul>
    <li>
    	5 - 8 years of IT - Data analytics experience in big data space like HDFS, data lake, data engineering using Python & Spark programming languages.
    </li>
    <li>
    Minimum 3 year of experience in Databricks workspace, Databricks notebooks, Job cluster, Delta Lake, Databricks Lakehouse and Unity catalog.
    </li>
    <li>
    Highly experienced in PySpark and Python development.
    </li>
    <li>
    Knowledge in implementation of data pipelines and Experience in processing streaming data.
    </li>
    <li>
    Databricks certified candidates are preferred.
    </li>
    <li>
    Hands on Experience working with global teams via Agile methodology - will be responsible for providing estimations on work modules and end to end SDLC.
    </li>
    <li>
    Must Have Experience In Problem-solving, Verbal And Communication Skills.
    </li>
 
    `,
    },
  ];

  const Testimonials = [
    {
      image: apsara,
      desc: "Working with Protecons makes me feel accomplished. At such early stage in my career, I got to work with the best people and a coveted role that offers new challenges each day.I am always given the autonomy and flexibility to work my way",
      name: "Apsara",
      designation: " SENIOR  CONSULTANT",
    },
    {
      image: viney,
      desc: "Being part of Protecons from its inception and having seen the growth of the company along with mine, has been some journey. Got opportunities in Guidewire to work on challenging projects that kept pushing my boundaries constantly. These opportunities along with the strong support system that we have bred in Protecons has helped mold my technical skills with client facing roles.",
      name: "Vinay",
      designation: " SENIOR TECHNICAL ARCHITECT ",
    },
  ];

  const tanu = {
    name: "Tanu Mishra",
    half: (
      <p>
        As we face rapid changes in business conditions, I believe “people” are
        the key to achieving sustained growth and increasing corporate value for
        Protecons...
      </p>
    ),
    full: (
      <p>
        As we face rapid changes in business conditions, I believe “people” are
        the key to achieving sustained growth and increasing corporate value for
        Protecons Keeping people @ core, our philosophy is to nurture and grow
        our human assets. Our focus is to create systems and work environments
        where each employee can work autonomously and fully utilize their
        individual abilities. We take pride in constantly engaging our employees
        towards continuous improvements and innovation to thrive business
        excellence. We cultivate a mindset for taking on challenges and career
        autonomy. We strongly promote diversity, equity, and inclusion (DE&I)
        measures for fully utilizing the abilities of each individual and
        striving to ensure that each employee can provide leadership. As we are
        growing exponentially, we are constantly looking to attract talent to
        drive the business forward. Join a team of world-class ProtecoNiNs who
        help clients across industries to transform their businesses. Be
        inspired to explore your passion and make an impact on organisations,
        industries, and society.
      </p>
    ),
  };
  const rimjhim = {
    name: "Rimjhim Gupta",
    half: (
      <p>
        {" "}
        At Protecons, we work collaboratively in our pursuit to build the value
        in every product and service, we deliver...
      </p>
    ),
    full: (
      <p>
        At Protecons, we work collaboratively in our pursuit to build the value
        in every product and service, we deliver. We strive to be innovative,
        and create best practices to ensure that the business has the right
        talent to deliver excellence at work. As talent builders, we empower our
        people with the right skills and mindset to grow as transformational
        leaders of the future. We believe in 'Do good, Be good' in every aspect
        of what we do which helps us create real value for our stakeholders-
        people, customers and society. Being here means to be at a place where
        people are valued, nurtured and empowered to explore their career and
        interests, beyond the usual. <br /> If you believe Protecons is the
        right place for you, then we invite you to join the family.
        <br /> Welcome to a world of opportunities!
      </p>
    ),
  };
  const anjali = {
    name: "Anjali Jaswar",
    half: (
      <p>
        I am here to guide you in this exciting journey of exploring
        opportunities at Protecons - whether you are an experienced
        professional...
      </p>
    ),
    full: (
      <p>
        I am here to guide you in this exciting journey of exploring
        opportunities at Protecons - whether you are an experienced professional
        seeking a challenging job that aligns with your ambitions or a fresher
        looking to kickstart your career. As a recruiter, I am committed to
        hiring the right talent with the right set of values. We are driven by
        people@core philosophy and our mission is to have “happy people all
        around”. We constantly focus to maximize our employee engagement because
        we believe that happy and engaged employees are the driving force
        towards organizational growth. We look for talents who are curious,
        passionate, and eager to learn new things in this ever-evolving job
        market where being adaptive to changes and continuous working on oneself
        to be market ready is the key to success.
        <br />
        I look forward to connecting with you and understanding your career
        aspirations to help you get the right platform to reach newer heights,
        that you are looking for!
        <br />
        So, If you are ready to take your skills to the next level, lets connect
        and embark on this journey together.
      </p>
    ),
  };

  const handleClear = () => {
    setDropdownData({
      key_Skills: "Key Skills",
      select_Country: "Select Country",
      experience: "Experience",
    });
  };
  const handleDropDown = (value, key) => {
    if (key === "ks") {
      setDropdownData({ ...dropDownData, key_Skills: value });
    } else if (key === "lcn") {
      setDropdownData({ ...dropDownData, select_Country: value });
    } else if (key === "exp") {
      setDropdownData({ ...dropDownData, experience: value });
    }
  };

  const loopReturn = (arr, no_of_item) => {
    let items = [];
    for (let i = 0; i < arr.length / no_of_item; i++) {
      items.push(
        <button
          type="button"
          data-bs-target="#carouselCaptions"
          data-bs-slide-to={i}
          class={i === 0 ? "active" : "text-light"}
          aria-current={i === 0 ? "true" : ""}
        ></button>
      );
    }
    return items;
  };

  const jobCardGroup = (arr, index) => {
    return (
      <div className="col-md-10 p-5">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          {arr.map((item, id) => {
            if (id >= index && id <= index + 3) {
              return (
                <>
                  <div class="col">
                    <JobCard data={item} />
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const TestimoniesCardGroup = (arr, index) => {
    return (
      <>
        {" "}
        {arr.map((item, id) => {
          if (id >= index && id <= index + 1) {
            return (
              <>
                <div className="col-md-10 mx-auto mt-5">
                  <TestimoniesCard data={{ ...item, id }} />
                </div>
              </>
            );
          }
        })}
      </>
    );
  };

  const carouselData = (arr, no_of_item) => {
    let items = [];
    arr.map((obj, index) => {
      if (index === 0 || index % no_of_item === 0) {
        items.push(
          <div class={`carousel-item ${index === 0 ? "active" : ""}`}>
            {no_of_item == 4
              ? jobCardGroup(arr, index)
              : no_of_item == 2
              ? TestimoniesCardGroup(arr, index)
              : null}
          </div>
        );
      }
    });
    return items;
  };

  const modal = (data) => {
    return (
      <>
        <div
          class="modal fade"
          id="exampleModals"
          tabindex="-1"
          aria-labelledby="exampleModalsLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabels">
                  {data?.name}
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="my-2">{strips.StripDesign1}</div>
                {data?.full}
                <div className="my-2">{strips.StripDesign2}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="fade-in-page">
        <section>
          <div className="allMaringTop">
            <Caurousel image1={image} image2={image} image3={image} />
          </div>
        </section>
        <section>
          <div className="card border-0 mt-5">
            <div className="card-body text-center">
              <h1 className="fw-bold text-secondary ">
                Find your dream job here
              </h1>
              <p className="text-secondary">
                We truly believe in life beyond work
              </p>
            </div>
            <div className="col-md-8 mx-auto">
              <div className="card border-0 shadow rounded-pill m-2">
                <div className="row m-2">
                  <div className="col-md-3  text-center">
                    <div class="dropdown">
                      <a
                        class="btn btn-transparent dropdown-toggle text-secondary fw-bold "
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {dropDownData.key_Skills.length <= 10
                          ? dropDownData.key_Skills
                          : dropDownData.key_Skills.slice(0, 20) + "..."}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(
                                "Guidewire Testing-Automation",
                                "ks"
                              )
                            }
                          >
                            Guidewire Testing-Automation
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown("Guidewire Configuration", "ks")
                            }
                          >
                            Guidewire Configuration
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown("Guidewire Integration", "ks")
                            }
                          >
                            Guidewire Integration
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(
                                "Guidewire Datahub Infocenter Data Migration(DHIC)",
                                "ks"
                              )
                            }
                          >
                            Guidewire Datahub <br />
                            Infocenter Data Migration(DHIC)
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(
                                "Guidewire Business Analysis",
                                "ks"
                              )
                            }
                          >
                            Guidewire Business Analysis
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(
                                "Guidewire Architecture-Functional",
                                "ks"
                              )
                            }
                          >
                            Guidewire Architecture-Functional
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown("Digital Portals", "ks")
                            }
                          >
                            Digital Portals
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(" Full Stack Development", "ks")
                            }
                          >
                            Full Stack Development
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() =>
                              handleDropDown(
                                "Guidewire Testing-Functional Human Resources Digital",
                                "ks"
                              )
                            }
                          >
                            Guidewire Testing-Functional Human Resources Digital
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown(" Marketing", "ks")}
                          >
                            Marketing
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 mx-auto text-center">
                    <div class="dropdown">
                      <a
                        class="btn btn-transparent dropdown-toggle  text-secondary fw-bold"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {dropDownData.select_Country}
                      </a>

                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("India", "lcn")}
                          >
                            India
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 mx-auto text-center">
                    <div class="dropdown">
                      <a
                        class="btn btn-transparent dropdown-toggle  text-secondary fw-bold "
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {dropDownData.experience}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("2-3 Years", "exp")}
                          >
                            2-3 Years
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("0-2 Years", "exp")}
                          >
                            0-2 Years
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("3-5 Years", "exp")}
                          >
                            3-5 Years
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("5-7 Years", "exp")}
                          >
                            5-7 Years
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("7-10 Years", "exp")}
                          >
                            7-10 Years
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => handleDropDown("10+ Years", "exp")}
                          >
                            10+ Years
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 mx-auto ">
                    <div className="text-center ">
                      <button
                        type="button"
                        class="btn btn-transparent shadow-sm rounded-circle text-secondary"
                      >
                        <BiSearchAlt />
                      </button>
                      <button
                        type="button"
                        class="btn btn-transparent shadow-sm rounded-pill ms-2 text-secondary"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-2 text-center displayFlex mb-4">
                <div className="btn-marginTop p-2">
                  <button type="button" class="btn btn-warning rounded-pill">
                    All Jobs
                  </button>
                </div>
                <div className="btn-marginTop p-2">
                  <button type="button" class="btn btn-warning rounded-pill">
                    Hot Jobs
                  </button>
                </div>
                <div className="btn-marginTop p-2">
                  <button type="button" class="btn btn-warning rounded-pill">
                    Tech Jobs
                  </button>
                </div>
              </div>
              <div className="col-md-10">
                <div id="carouselCaptions" class="carousel slide">
                  <div class="carousel-indicators activeDots carousel-cus-indicators">
                    {loopReturn(data4, 4)}
                  </div>
                  <div class="carousel-inner">{carouselData(data4, 4)}</div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselCaptions"
                    data-bs-slide="prev"
                  >
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next carousel-control-next-btn "
                    type="button"
                    data-bs-target="#carouselCaptions"
                    data-bs-slide="next"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-warning rounded-pill "
                    >
                      <BsArrowRight size={20} />
                    </button>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="card border-0 rounded-0 bg-light">
              <div className="card-body mt-5">
                <h1 className="text-secondary fw-bold text-center ">
                  Employee Testimonies
                </h1>
                <div className="mb-5">{strips.StripDesign3}</div>
                <div id="carousel-Testimonials-Captions" class="carousel slide">
                  <div class="carousel-indicators activeDots carousel-Testimonial-indicators">
                    {loopReturn(Testimonials, 2)}
                  </div>
                  <div class="carousel-inner">
                    {carouselData(Testimonials, 2)}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carousel-Testimonials-Captions"
                    data-bs-slide="prev"
                  >
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next carousel-control-next-btn "
                    type="button"
                    data-bs-target="#carousel-Testimonials-Captions"
                    data-bs-slide="next"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-warning rounded-pill "
                    >
                      <BsArrowRight size={20} />
                    </button>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="col-md-4 mt-5 ">
            <div className="card border-0 ">
              <div className="card-body mx-auto mt-5">
                {strips.StripDesign1}
                <h1 className="text-secondary fw-bold">
                  Recruiters <br />
                  working for you
                </h1>
                <div></div>
                {strips.StripDesign2}
              </div>
            </div>
          </div>
        </section>

        <section>
          <section>
            <div id="reqruterCarousel" class="carousel carousel-dark slide">
              {/* <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#reqruterCarousel"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
            </div> */}
              <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="10000">
                  <div className="container-fulid">
                    <div className="row">
                      <div className="col-md-4 mx-auto">
                        <div className="container mt-5 ">
                          <div className="cards">
                            <div className="image imageCardRecrutor">
                              <img src={hr2} />
                            </div>
                            <div className="content">
                              <h4>{tanu.name}</h4>
                              {tanu.half}
                              <Link to="https://in.linkedin.com/in/tanu-mishra-b79aa2209?trk=people-guest_people_search-card" target="_blank"><ImLinkedin size={25} className="mt-1" color="#029499"/></Link>
                              <button
                                type="button"
                                class="btn  rounded-pill float-end  btn-green  text-white"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModals"
                                onClick={() => setModalData(tanu)}
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mx-auto">
                        <div className="container mt-5">
                          <div className="cards ">
                            <div className="image imageCardRecrutor">
                              <img src={hr1} />
                            </div>
                            <div className="content">
                              <h4>{rimjhim?.name}</h4>
                              {rimjhim?.half}
                              <Link to="https://in.linkedin.com/in/rimzhim-gupta-b5a23a55?trk=people-guest_people_search-card" target="_blank"><ImLinkedin size={25} className="mt-1" color="#029499"/></Link>
                              <button
                                type="button"
                                class="btn  rounded-pill float-end btn-green  text-white "
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModals"
                                onClick={() => setModalData(rimjhim)}
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mx-auto">
                        <div className="container mt-5">
                          <div className="cards  card-neon-bg">
                            <div className="image imageCardRecrutor">
                              <img src={hr3} />
                            </div>

                            <div className="content">
                              <h4>{anjali.name}</h4>
                              {anjali.half}
                              <Link to="https://in.linkedin.com/in/anjali-jaiswar-5786bb245?trk=people-guest_people_search-card" target="_blank"><ImLinkedin size={25} className="mt-1" color="#029499"/></Link>
                              <button
                                type="button"
                                class="btn btn-green  text-white rounded-pill float-end "
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModals"
                                onClick={() => setModalData(anjali)}
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#reqruterCarousel"
                data-bs-slide="prev"
              >
                <span class="visually-hidden">Previous</span>
              </button> */}
              <button
                class="carousel-control-next carousel-control-next-btn "
                type="button"
                data-bs-target="#reqruterCarousel"
                data-bs-slide="next"
              >
                <button
                  type="button"
                  class="btn btn-outline-warning rounded-pill "
                >
                  <BsArrowRight size={20} />
                </button>
                <span class="visually-hidden">Next</span>
              </button>

              {/* <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#reqruterCarousel"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#reqruterCarousel"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button> */}
            </div>
          </section>
        </section>
      </div>
      {modal(modalData)}
    </>
  );
};
