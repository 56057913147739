import React from 'react'
import logo from '../Media/Images/logo2.png'
import { MdLocationPin } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import { BsLinkedin } from 'react-icons/bs'

export const Footer = () => {
    let footerStyle = {
        top: "100%",
        width: "100%",
    }

    return (
        <footer className='footerBg mt-5' style={footerStyle}>
            <div className='container-fluid SubHeadings'>
                <div className='row'>
                    <div className='col-md-3 mt-5'>
                        <div className='card bg-transparent border-0 mt-4'>
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className='col-md-3  mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-4" style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title"><MdLocationPin />Regd. Office: Bareilly</h5>
                                <p className='addresss'>Swastik Towers, CP-17/18,Mahanagar, Pilibhit Bypass
                                    Road, </p>
                                <p className='addresss'>Bareilly, Uttar Pardesh - 243005 India</p>
                                <div className='d-flex'>
                                    <div className=''>
                                        <IoLogoWhatsapp size={30} />
                                    </div>
                                    <div className='mx-4'>
                                        <BsLinkedin size={25} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-4" style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title"><MdLocationPin />Branch Office : Bangalore</h5>
                                <p className='addresss'>Indiqube ALPHA, Plot No. 19/4 & 27,Kadubeesanahalli Village,</p>
                                <p className='addresss'>Varthur Hobli, Bangalore , </p>
                                <p className='addresss'>Karnataka - 560103 India</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className='col-md-3  mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-4" style={{ width: "15rem" }}>
                            <div className="card-body">
                                <ul className="list-group list-group-flush ">
                                    <li className="list-group-item  bg-transparent  footerLink" > <NavLink to='/' id="style-2" className="text-light " data-replace="Home" onClick={() => window.scrollTo(0, 0)}><span>Home</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/guidewire" id="style-2" className="text-light" data-replace="Services" onClick={() => window.scrollTo(0, 0)}><span>Services</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/contactus" id="style-2" className="text-light" data-replace="Contact Us" onClick={() => window.scrollTo(0, 0)}><span>Contact Us</span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-dark text-light p-0' >
                <div className='d-flex p-0 '>
                    <div className='p-3 mx-auto text-secondary'>
                        &copy; Protecons 2024. All Rights Reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}
