import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/bootstrap/js/dist/dropdown.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./global.scss";
import { Careers } from "./Pages/Careers";
import { JobApplication } from "./Pages/JobApplication";
import { Home } from "./Pages/Home";
import { GuidewireServices } from "./Pages/GuidewireServices.jsx";
import { Footer } from "./components/Footer.jsx";
import { Navbar } from "./components/Navbar.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContectUs } from "./Pages/ContectUs.jsx";
import { AboutUS } from "./Pages/AboutUs.jsx";
import { JobApplicationForm } from "./Pages/JobApplicationForm.jsx";
import { Gptw } from "./Pages/Gptw.jsx";
import { ProTLabs } from "./Pages/proTlabs.jsx";
import { SkillsTraining } from "./Pages/SkillsTraining.jsx";

AOS.init({
  duration:1200,
  delay:500
});
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/career" element={<Careers />} />
        <Route path="/jobApplication" element={<JobApplication/>} />
        <Route path="/" element={<Home/>} />
        <Route path="/guidewire" element={<GuidewireServices />} />
        <Route path="/contactus" element={<ContectUs />} />
        <Route path="/aboutUs" element={<AboutUS/>} />
        <Route path="/apply-now" element={<JobApplicationForm/>} />
        <Route path="/Gptw" element={<Gptw/>} />
        <Route path="/proTlabs" element={<ProTLabs/>} />
        <Route path="/skillstraining" element={<SkillsTraining/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
