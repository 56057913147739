import React from 'react'
import strips from './StripDesign'


export const CareersPageCard = (props) => {
    const {data} = props
    return (
        <> 
            <div className='card shadow border-0 cardRadi overflowHidden '>
                <div className='card-body '>
                    {strips.StripDesign1}
                    <h4 className='fw-bold text-center mt-4'>{data?.title}</h4>
                    <p className='px-5 mt-4'>{data?.desc}</p>
                    {strips.StripDesign2}
                </div>

            </div>
        </>
    )
}