import { Carousel } from "bootstrap";
import React from "react";
import { Caurousel } from "../components/Caurousel";
import { MainCarousel } from "../components/MainCarousel";
import strips from "../components/StripDesign";
import fullStack from "../Media/Images/fullStack.png";
import { ImageCard } from "../components/ImageCard";
import html from "../Media/Images/html-5.svg";
import css from "../Media/Images/css.svg";
import js from "../Media/Images/js.svg";
import angulara from "../Media/Images/angular.svg";
import react from "../Media/Images/react.svg";
import { ClientsCard } from "../components/ClientsCard";
import image1 from "../Media/Images/ProdSlider1.svg";
import image2 from "../Media/Images/ProdSlider2.svg";
import image3 from "../Media/Images/ProdSlider3.svg";
import image4 from "../Media/Images/ProSlider4.svg";
import wordpress from "../Media/Images/Wordpress.svg";
import magento from "../Media/Images/Magento.svg";
import node from "../Media/Images/nodejs.png";
import express from "../Media/Images/express.png";
import mysql from "../Media/Images/sql.png";
import mongodb from "../Media/Images/mongo.png";
import azure from "../Media/Images/azure.svg";
import aws from "../Media/Images/aws.svg"
import gcp from "../Media/Images/gcp.svg";
import java from "../Media/Images/java.png"
import dotNet from "../Media/Images/website.png"
import postgresql from "../Media/Images/postgres.png";
import silverLight from "../Media/Images/silverLight.png"

export const Home = () => {
  return (
    <>
      <div className="fade-in-page overflowHidden">
        <div className="allMaringTop">
          <Caurousel image1={image4} image2={image2} image3={image3} />
        </div>
        <section>
          <div className="fw-bold mt-5">
            <h1 className="text-center text-secondary fw-bold mt-5">
              Our Clients
              <span className="ProText_green">.</span>
            </h1>
            {strips.StripDesign3}
          </div>
          <ClientsCard />
        </section>
        <sectino>
          <div className="mt-5">
            <div className="fw-bold mt-5">
              <h1 className="text-center text-secondary fw-bold mt-5">
                Product Engineering Services
                <span className="ProText_green">.</span>
              </h1>
              {strips.StripDesign3}
            </div>
            <MainCarousel />
          </div>
        </sectino>

        <section>
          <div className="col-md-11 mx-auto p-0 m-0">
            <div className="card border-0 mt-5 cardBottomRadi cardBgImage shadow">
              <div className="card-body p-5">
                <div className="card border-0  bg-transparent">
                  <div className="card-body text-white">
                    <h4
                      className="card-title fw-bold text-center "
                      data-aos="fade-down"
                    >
                      Build{" "}
                      <span className="textColor">
                        next-gen digital products{" "}
                      </span>
                      with our {" "}
                      <span className="textColor">
                        innovative Product Engineering Services
                      </span>
                      <br /> to streamline operations and drive growth
                    </h4>

                    <div className="row mt-5">
                      <div className="col-md-6 mx-auto">
                        <div className="col-md-10 ">
                          <div className=" mx-auto mt-2">
                            <p className="card-text fs-5" data-aos="fade-right">
                              <br />
                              Deep domain expertise <br />
                              <br />
                              Faster time to market <br />
                              <br /> Tailor-Made Solutions based on Unique
                              business needs
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ms-auto">
                        <div className=" mx-auto mt-2">
                          <p className="card-text fs-5" data-aos="fade-left">
                            <br />
                            Team of world-class app developers <br />
                            <br /> End-to-end implementation <br />
                            <br />
                            Full stack app development
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-center fs-5 fw-bold textColor "
                      data-aos="fade-up"
                    >
                      <p>
                        <br />
                        <br />
                        Our solutions enable you to maintain agility,
                        responsiveness and gain competitive edge in the market
                      </p>
                      <button
                        type="button"
                        class="btn btn-outline-light btn-lg  rounded-pill"
                      >
                        Get Free Consultation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="col-md-11 mx-auto mt-5">
            <div className="my-5 ">
              <h1 className="fw-bold text-secondary text-center">
                Our Services<span className="ProText_green">.</span>
              </h1>
              {strips.StripDesign3}
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col">
                <div className="card border-0 shadow CardFixedHight cardStyle2 card-hover ">
                  <div className="card-body  p-4 mt-4">
                    <h5 className="text-secondary fw-bold">
                      New Product Development
                    </h5>
                    <p className="text-secondary">
                      Translate abstract ideas into cutting-edge products with
                      the power of product thinking and an innovation mindset.
                      Quickly develop a working prototype or MVP having
                      essential features and test it with your target customers.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="card border-0 shadow CardFixedHight card-hover">
                  <div className="card-body  p-4 mt-4">
                    <h5 className="text-secondary fw-bold">
                      Continuous Product Innovation
                    </h5>
                    <p className="text-secondary">
                      Continuous innovation encompasses a comprehensive set of
                      organizational capabilities such as operational
                      excellence, technology infrastructure to support
                      continuous delivery, and an innovation mindset that’s
                      perfectly in tune with evolving market demands.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="card border-0 shadow CardFixedHight card-hover">
                  <div className="card-body  p-4 mt-4">
                    <h5 className="text-secondary fw-bold">
                      Product Implementation
                    </h5>
                    <p className="text-secondary">
                      As part of our full lifecycle support, our CoE team helps
                      you with integrations, customizations, enhancements and
                      deployment. We walk the extra mile to ensure that they are
                      technically accurate and well-tested prior to going live.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="card border-0 shadow CardFixedHight card-hover">
                  <div className="card-body  p-4 mt-4">
                    <h5 className="text-secondary fw-bold">
                      Quality Engineering
                    </h5>
                    <p className="text-secondary">
                      We integrate Quality Engineering into every aspect of the
                      product development lifecycle and do not pursue a separate
                      testing phase. Giving you an early edge in making the
                      product market ready before competitors.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="card border-0 shadow CardFixedHight card-hover">
                  <div className="card-body  p-4 mt-4">
                    <h5 className="text-secondary fw-bold">
                      Agile Development
                    </h5>
                    <p className="text-secondary">
                      Agile development is no longer just a luxury but rather an
                      imminent need. Our Agile Development model includes Agile
                      strategy & consulting, Enterprise agile strategy &
                      implementation, and Agile coaching and mentoring.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="col-md-11 mx-auto mt-5">
            <div className="my-5 ">
              <h1 className="fw-bold text-secondary text-center">
                Technologies We Serve<span className="ProText_green">.</span>
              </h1>
              {strips.StripDesign3}
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4 overflowHidden">
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2 "
                  data-aos="fade-right"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">See Before You Build </p>
                    <p>
                      Very often, the difference between a successful launch and
                      a successful run is in the details. Our unique Pro(T) lab,
                      at Protecons, acts as a mini incubation center for your
                      app idea.
                    </p>
                    {/* <button
                    type="button"
                    class="btn btn-sm shadow-sm 
                            rounded-pill shadow text-secondary fs-6 mb-4"
                  >
                    <p className="pb-0 mb-0 ProText_orange">
                      Explore <span style={{ fontSize: "20px" }}>&#8594;</span>
                    </p>
                  </button> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-up"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Mobile app </p>
                    <p>
                      We are experts in building custom mobile apps on iOS,
                      Android, Hybrid apps for enterprises to help them engage
                      with employees, customers, partners and vendors.
                    </p>
                    {/* <button
                    type="button"
                    class="btn btn-sm shadow-sm 
                            rounded-pill shadow text-secondary fs-6 mb-4"
                  >
                    <p className="pb-0 mb-0 ProText_orange">
                      Explore <span style={{ fontSize: "20px" }}>&#8594;</span>
                    </p>
                  </button> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-left"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Web development </p>
                    <p>
                      Our web application development services starts from a
                      simple CMS enabled website to designing and developing the
                      most complex web applications, e-commerce portals and
                      progressive web applications.
                    </p>
                    {/* <button
                    type="button"
                    class="btn btn-sm shadow-sm 
                            rounded-pill shadow text-secondary fs-6 mb-4"
                  >
                    <p className="pb-0 mb-0 ProText_orange">
                      Explore <span style={{ fontSize: "20px" }}>&#8594;</span>
                    </p>
                  </button> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-right"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Cloud </p>
                    <p>
                      Our cloud services will enable your enterprise to connect
                      with local customers, partners and employees while also
                      expand globally to deliver differentiated services to your
                      end users from anywhere.
                    </p>
                    {/* <button
                    type="button"
                    class="btn btn-sm shadow-sm 
                            rounded-pill shadow text-secondary fs-6 mb-4"
                  >
                    <p className="pb-0 mb-0 ProText_orange">
                      Explore <span style={{ fontSize: "20px" }}>&#8594;</span>
                    </p>
                  </button> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-left"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">DevOps </p>
                    <p>
                      Our DevOps consulting services help companies to set up,
                      automate, and manage configuration environments to improve
                      the software development and delivery for rendering
                      uninterrupted customer experience like never before.
                    </p>
                    {/* <button
                    type="button"
                    class="btn btn-sm shadow-sm 
                            rounded-pill shadow text-secondary fs-6 mb-4"
                  >
                    <p className="pb-0 mb-0 ProText_orange">
                      Explore <span style={{ fontSize: "20px" }}>&#8594;</span>
                    </p>
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="my-5">
            <h1 className="fw-bold text-secondary text-center">
              Technologies We Use<span className="ProText_green">.</span>
            </h1>
            {strips.StripDesign3}
          </div>

          <div className="conatiner-fluid overflowHidden">
            <div className="row p-0 m-0 mb-4">
              <div className="col-lg-4 ps-0 ms-0 mt-4">
                <div
                  className="card border-0 shadow bg-light cardBottomRadi-2 mt-4"
                  data-aos="fade-right"
                >
                  <div className="card-body ">
                    <ImageCard image={fullStack} />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 ms-auto pe-0 me-0 mt-4">
                <div className="card border-0 shadow bg-light cardBottomRadi-3 card-hover-left ">
                  <div className="card-body text-center">
                    <div className="row ">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="my-2">
                              <span class="badge rounded-pill bg-warning text-dark mt-2 ">
                                PRESENTATION
                              </span>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <img
                              src={html}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />

                            <img
                              src={css}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />

                            <img
                              src={js}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />

                            <img
                              src={angulara}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />

                            <img
                              src={react}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="my-2">
                              <span class="badge rounded-pill bg-warning text-dark mt-2">
                                CMS
                              </span>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <img
                              src={magento}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />

                            <img
                              src={wordpress}
                              class="img-fluid mx-2"
                              alt="..."
                              width="30rem"
                            />
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border-0 shadow bg-light cardBottomRadi-3 mt-4 card-hover-left">
                  <div className="card-body text-center">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="my-3">
                              <span class="badge rounded-pill bg-warning text-dark ">
                                FRAMEWORK
                              </span>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <img
                              src={node}
                              class="img-fluid ms-3"
                              alt="..."
                              width="50rem"
                            />

                            <img
                              src={express}
                              class="img-fluid mx-2"
                              alt="..."
                              width="40rem"
                            />
                             <img
                              src={java}
                              class="img-fluid mx-2"
                              alt="..."
                              width="40rem"
                            />
                            <img
                              src={dotNet}
                              class="img-fluid mx-2"
                              alt="..."
                              width="40rem"
                            />
                            <img
                              src={silverLight}
                              class="img-fluid ms-2"
                              alt="..."
                              width="60rem"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="my-2">
                              <span class="badge rounded-pill bg-warning text-dark mt-2">
                                CLOUD
                              </span>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <img
                              src={azure}
                              class="img-fluid mx-2 mt-3"
                              alt="..."
                              width="40rem"
                            />

                            <img
                              src={gcp}
                              class="img-fluid mt-3 mx-2"
                              alt="..."
                              width="40rem"
                            />
                            <img
                              src={aws}
                              class="img-fluid mt-3"
                              alt="..."
                              width="40rem"
                            />
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border-0 shadow bg-light cardBottomRadi-3 mt-4 card-hover-left">
                  <div className="card-body text-center">
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="my-2">
                              <span class="badge rounded-pill bg-warning text-dark mt-2">
                                DATABASE
                              </span>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <img
                              src={mysql}
                              class="img-fluid mx-2"
                              alt="..."
                              width="40rem"
                            />

                            <img
                              src={mongodb}
                              class="img-fluid mx-3"
                              alt="..."
                              width="40rem"
                            />

                            <img
                              src={postgresql}
                              class="img-fluid mx-2"
                              alt="..."
                              width="40rem"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          <div>
                            <span class="badge rounded-pill bg-warning text-dark mt-2">
                              Presentation
                            </span>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <img
                            src={html}
                            class="img-fluid mx-2"
                            alt="..."
                            width="30rem"
                          />

                          <img
                            src={css}
                            class="img-fluid mx-2"
                            alt="..."
                            width="30rem"
                          />

                          <img
                            src={js}
                            class="img-fluid mx-2"
                            alt="..."
                            width="30rem"
                          />

                          <img
                            src={angulara}
                            class="img-fluid mx-2"
                            alt="..."
                            width="30rem"
                          />

                          <img
                            src={react}
                            class="img-fluid mx-2"
                            alt="..."
                            width="30rem"
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="col-lg-11 mx-auto mt-5">
            <div className="my-5">
              <h1 className="fw-bold text-secondary text-center">
                Our Approach<span className="ProText_green">.</span>
              </h1>
              {strips.StripDesign3}
            </div>

            <div class="row row-cols-1 row-cols-md-4 g-2 overflowHidden">
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2 "
                  data-aos="fade-right"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Refine</p>
                    <p className="mb-4">
                      Idea conceptualization and validation. Our market experts
                      help you to research, analyze and conceptualize the right
                      market fit. Refine your idea to understand your users
                      persona, design the wireframes and Screens.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-up"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Propel</p>
                    <p>
                      Develop a Minimum Viable Product (MVP) in few weeks to go
                      to market with a soft launch to gather use feedback.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-down"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Improve</p>
                    <p>
                      Get user feedbacks to modify and enhance the product till
                      you achieve Product-Market fit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first CardFixedHight2"
                  data-aos="fade-left"
                >
                  <div className="card-body text-secondary">
                    <p className="fs-5 fw-bold">Expand</p>
                    <p>
                      Improve the product by taking care of scalability,
                      performance, and usability through continuous improvement
                      and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="col-md-11 mx-auto mt-5">
            <div className="my-5 ">
              <h1 className="fw-bold text-secondary text-center">
                Why Us<span className="ProText_green">.</span>
              </h1>
              {strips.StripDesign3}
            </div>
          </div>
          <div className="container-fluid p-0 m-0 overflowHidden">
            <div className="col-md-8">
              <div
                className="card border-0 cardBottomRadi-2 bg-light shadow  "
                data-aos="fade-right"
              >
                <div className="card-body p-5 text-center">
                  <p className=" fs-3 text-secondary">
                    Solution Arcitects to understand <br></br> business problems
                    and develop optimal solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8 ms-auto mt-4">
              <div
                className="card border-0 cardBottomRadi-3 bg-light shadow "
                data-aos="fade-left"
              >
                <div className="card-body p-5 text-center">
                  <p className="fs-3 text-secondary">
                    We comprehend industry specific challenges <br></br> and
                    apply design thinking.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-4 mb-4">
              <div
                className="card border-0 cardBottomRadi-2 bg-light shadow "
                data-aos="fade-right"
              >
                <div className="card-body p-5 text-center">
                  <p className="fs-3 text-secondary">
                    Multiple enterprise engagements with <br /> demonstrated ROI
                    for the solutions deployed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

          <section>
            <div className="col-md-11 mx-auto mt-5 mb-5">
              <div className="my-5 ">
                <h1 className="fw-bold text-secondary text-center">
                  Our Value Proposition<span className="ProText_green">.</span>
                </h1>
                {strips.StripDesign3}
              </div>
              <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col">
                  <div className="card border-0 shadow CardFixedHight card-hover">
                    <div className="card-body  p-4 mt-4">
                      <h5 className="text-secondary fw-bold">Seamless UX</h5>
                      <p className="text-secondary">
                        We use our rich and vast experience in developing
                        user-centric UX/UI that blend usability, experience and
                        impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="card border-0 shadow CardFixedHight card-hover">
                    <div className="card-body  p-4 mt-4">
                      <h5 className="text-secondary fw-bold">
                        Scalable Architecture
                      </h5>
                      <p className="text-secondary">
                        We develop systems with Micro App architecture for
                        greater scalability.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="card border-0 shadow CardFixedHight card-hover">
                    <div className="card-body  p-4 mt-4">
                      <h5 className="text-secondary fw-bold">
                        Rapid application development
                      </h5>
                      <p className="text-secondary">
                        We employ robust SDK libraries for different
                        functionalities to ensure a high performing application.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="card border-0 shadow CardFixedHight card-hover">
                    <div className="card-body  p-4 mt-4">
                      <h5 className="text-secondary fw-bold">
                        Technology Excellence
                      </h5>
                      <p className="text-secondary">
                        We use advanced capabilities in integrating tools like
                        Google Maps for adding the touch of reliability,
                        sophistication and usability.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="card border-0 shadow CardFixedHight card-hover">
                    <div className="card-body  p-4 mt-4">
                      <h5 className="text-secondary fw-bold">
                        Powerful Analytics
                      </h5>
                      <p className="text-secondary">
                        We bake in a powerful Analytics platform to track,
                        monitor and manage the system performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    </>
  );
};
