import React from 'react'

export const VideoCard = (props) => {
    const { video, dimensions } = props
    return (
        <>
            <div className='card border-0' >
                <video className="my-video rounded" autoPlay loop muted style={{ height: dimensions?.h }}>
                    <source src={video} type="video/mp4" />
                </video>
            </div>
        </>
    )
}
