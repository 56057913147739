import React from "react";

export const MainCarousel = () => {
  return (
    <>
      <div
        id="carouselExampleInterval"
        className="carousel slide mt-5 "
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active " data-bs-interval="10000">
            <div className="card text-center border-0 text-light bg-transparent">
              <div className="card-body text-secondary">
                <h1 className="card-title ">Reinventing Across Industries</h1>
                <div className="col-md-6 mx-auto mt-5">
                  <p className="card-text fs-5">
                    We specialize in providing comprehensive product engineering
                    services to Insurance, Manufacturing, Pharmaceutical, Food & Beverages and
                    Retail industries worldwide.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <div className="card text-center border-0  bg-transparent">
              <div className="card-body text-secondary">
                <h1 className="card-title ">Fueling Digital Transformation</h1>
                <div className="col-md-6 mx-auto mt-5">
                  <p className="card-text fs-5">
                    At Protecons, our mission is to assist you in your digital
                    transformation journey and drive growth. With our expertise,
                    your enterprise can effectively keep up with the rapidly
                    evolving market demands, deliver highly targeted services,
                    and implement cutting-edge software product engineering
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center border-0 text-light bg-transparent ">
              <div className="card-body text-secondary">
                <h1 className="card-title ">Maximize your 'Return on Innovation'</h1>
                <div className="col-md-6 mx-auto mt-5">
                  <p className="card-text fs-5">
                    Our goal is to empower businesses like yours to turn 
                    innovative ideas into successful market-ready products that
                    gives you maximum ROI (Return on Innovation) allowing you to remain business-focused  . As an
                    end-to-end software product engineering partner, we take
                    care of every stage of the product lifecycle, including
                    ideation, design, prototyping, finding product-market fit,
                    full development, launch, and scaling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon1"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon1"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};
