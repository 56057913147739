import React from 'react'

export const ImageCard = (props) => {
    const { image ,dimensions } = props;
    return (
        <div class="card border-0 bg-transparent">
            <img src={image} class="card-img-top cardRadi  " alt="..." style={{width:dimensions?.w , height:dimensions?.h}}   />
        </div>
    )
}
