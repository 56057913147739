const StripDesign1 = (
  <div>
    <div className="d-flex">
      <span class="badge rounded-pill  ms-1 me-1  pt-0 Pro_orange" data-aos="fade-right" data-aos-delay="0" >
        ...........
      </span>
      <span class="badge rounded-pill  ms-1 me-1  pt-0 Pro_yellow" data-aos="fade-right" data-aos-delay="50" >
        ........
      </span>
      <span class="badge rounded-pill  ms-1 me-1  pt-0 Pro_green" data-aos="fade-right" data-aos-delay="100">.</span>
    </div>
  </div>
);

const StripDesign2 = (
  <div className="d-flex">
    <div className="ms-auto">
      <span class="badge rounded-pill  ms-1 me-1 pt-0 Pro_green" data-aos="fade-left" data-aos-delay="100">.</span>
      <span class="badge rounded-pill ms-1 me-1  pt-0 Pro_yellow" data-aos="fade-left" data-aos-delay="50">
        ........
      </span>
      <span class="badge rounded-pill  ms-1 me-1  pt-0 Pro_orange" data-aos="fade-left" data-aos-delay="0">
        ...........
      </span>
    </div>
  </div>
);

const StripDesign3 = (
  <div className="d-flex">
    <div className="mx-auto">
      <span class="badge rounded-pill  ms-1 me-1 pt-0 Pro_green"  data-aos="fade-right">.</span>
      <span class="badge rounded-pill ms-1 me-1  pt-0 Pro_yellow"  data-aos="fade-up">
        ........
      </span>
      <span class="badge rounded-pill  ms-1 me-1  pt-0 Pro_orange"  data-aos="fade-down">
        .........
      </span>
      <span class="badge rounded-pill  ms-1 me-1 pt-0 Pro_green"  data-aos="fade-left">.</span>
    </div>
  </div>
);

const StripDesign4 = (
  <div className="d-flex m-2">
    <div className="mx-auto ">
      <div>
        <span class="badge rounded-pill  my-1 pt-0 float-end Pro_green" data-aos="fade-right" data-aos-delay="0">.</span>
      </div>
      <div>
        <span class="badge rounded-pill my-1   pt-0 float-end Pro_yellow" data-aos="fade-right" data-aos-delay="50">
          .........
        </span>
      </div>
      <div>
        <span class="badge rounded-pill  my-1   pt-0 float-end Pro_orange" data-aos="fade-right" data-aos-delay="100">
          .........
        </span>
      </div>
      <div>
        <span class="badge rounded-pill my-1   pt-0 float-end Pro_green" data-aos="fade-right" data-aos-delay="150">
          .
        </span>
      </div>
    </div>
  </div>
);


const StripDesign5 = (
  <div className="d-flex m-2">
    <div className="mx-auto ">
      <div>
        <span class="badge rounded-pill  my-1 pt-0  Pro_green"  data-aos="fade-left" data-aos-delay="0">.</span>
      </div>
      <div>
        <span class="badge rounded-pill my-1   pt-0  Pro_yellow "  data-aos="fade-left" data-aos-delay="50">
          .........
        </span>
      </div>
      <div>
        <span class="badge rounded-pill  my-1   pt-0  Pro_orange"  data-aos="fade-left" data-aos-delay="100">
          .........
        </span>
      </div>
      <div>
        <span class="badge rounded-pill my-1   pt-0  Pro_green"  data-aos="fade-left" data-aos-delay="150">
          .
        </span>
      </div>
    </div>
  </div>
);
const strips = { StripDesign1, StripDesign2, StripDesign3, StripDesign4,StripDesign5 };

export default strips;
