import React, { useEffect, useRef, useState } from "react";
import jobApplicationCover from "../Media/Images/JobApplication1.svg";
import { useLocation } from "react-router-dom";
import { BsWindowDesktop } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import strips from "../components/StripDesign";
import DOMPurify from "dompurify";
import { useForm } from "react-hook-form";
import { send } from "emailjs-com";
import { Modal } from "reactstrap";
import sendGif from "../Media/Images/sendGif.gif";

import countryStatCity from "../Media/countries+states+cities.json";

export const JobApplicationForm = (props) => {
  const location = useLocation();
  const [jobData, setJobData] = useState({});
  const [indexes, setIndexes] = useState({ state: null, city: null });
  const targetRef = useRef(null);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [modal, setModal] = useState(false);

  const submit = (data) => {
    console.log("data", data);
    // send("service_uxjy11s", "template_ia3j76m", data, "90wuaN9OE1t7EaKR_")
    //   .then((response) => {
    //     console.log("SUCCESS", response.status, response.text);
    //     reset();
    //     setModal(true);
    //   })
    //   .catch((err) => {
    //     console.log("FAILED...", err);
    //   });
  };

  const handleSelect = (event, type) => {
    switch (type) {
      case "country":
        setIndexes({ ...indexes, state: event.target.selectedIndex - 1 });
        break;
      case "state":
        setIndexes({ ...indexes, city: event.target.selectedIndex - 1 });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.state) {
      setJobData(location?.state?.data);
    }
  }, []);

  const SanitizedHTMLRenderer = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    return (
      <p
        className="text-secondary"
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
    );
  };

  return (
    <>
      <div className="fade-in-page">
        <div class="card mb-3 border-0 allMaringTop">
          <img src={jobApplicationCover} class="card-img-top" alt="..." />
        </div>
        <div className="conatiner-fluid">
          <div className="row m-0 p-0">
            <div className="col-md-4 mx-auto">
              <div className="card border-0 shadow cardRadi mt-5">
                <div className="card-body p-4">
                  {strips.StripDesign1}
                  <h5 className="text-secondary mt-3">
                    Job ID {jobData?.jobId}
                  </h5>
                  <div className="text-secondary">
                    <span>
                      <BsWindowDesktop />
                    </span>
                    <span className="fw-normal">{jobData?.postUpdatedAt}</span>
                  </div>
                  <h2 className="fw-bold ProText_orange mt-4">
                    {jobData?.title}
                  </h2>
                  <span className="text-secondary">
                    <BsWindowDesktop />
                  </span>
                  <span className="fs-5 text-secondary">
                    Experience {jobData?.experence}
                  </span>
                  <div>
                    <span className="text-secondary">
                      <MdLocationPin />
                    </span>
                    <span className="fs-5 text-secondary">
                      {jobData?.location}
                    </span>
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      type="button"
                      class="btn  btn-lg rounded-pill shadow-sm text-secondary"
                      onClick={handleScroll}
                    >
                      <p className="p-0 m-0 px-5">
                        Apply now
                        <span
                          style={{ fontSize: "25px" }}
                          className="ProText_green"
                        >
                          &#8594;
                        </span>
                      </p>
                    </button>
                  </div>
                  {strips.StripDesign2}
                </div>
              </div>
            </div>
            <div className="col-md-8 mx-auto">
              <div className="card border-0 shadow cardRadi">
                <div className="card-body">
                  <h4 className="text-secondary ms-4 mb-2">
                    Job Responsibilities
                  </h4>
                  {SanitizedHTMLRenderer(jobData?.jobResponsebilities)}
                </div>
              </div>
            </div>
            <div className="container-fluid mt-4 ">
              <div className="card border-0 shadow cardRadi">
                <div className="card-body">
                  <h4 className="text-secondary ms-4 mb-2">Job Requirements</h4>
                  {SanitizedHTMLRenderer(jobData?.jobRequirments)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5" ref={targetRef}></div>
        <div className="col-md-10  mx-auto mt-5 ">
          <div className="card   border-0 bg-transparent shadow cardRadi">
            <div className="card  border-0  p-0 text-center  ">
              <div className="card-body SubHeadings">
                <h3 className="card-title text-secondary ">
                  <strong>My Information</strong>
                </h3>
              </div>
            </div>

            <form className="row g-3 p-4  text-secondary">
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputFname" className="form-label">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control bg-transparent text-secondary border-0 inputBorder "
                    id="inputFname"
                    {...register("fname", { required: true })}
                  />
                  {errors.fname?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *First name is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputLname" className="form-label">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control bg-transparent text-secondary border-0 inputBorder "
                    id="inputLname"
                    {...register("Lname", { required: true })}
                  />
                  {errors.Lname?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *Last name is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputPhone" className="form-label ">
                    Phone*
                  </label>
                  <input
                    type="phone"
                    className="form-control bg-transparent text-secondary border-0 inputBorder"
                    id="inputPhone"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *Phone number is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputEmail" className="form-label">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control bg-transparent text-secondary border-0 inputBorder"
                    id="inputEmail"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <p role="aler" className="text-danger">
                      *email is required
                    </p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p role="aler" className="text-danger">
                      *invalid email
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputCountry" className="form-label">
                    Country*
                  </label>
                  <select
                    class="form-select border-0 inputBorder"
                    aria-label="Default select example"
                    id="inputCountry"
                    {...register("country", { required: true })}
                    onChange={(e) => handleSelect(e, "country")}
                  >
                    <option value="">Select</option>
                    {countryStatCity.map((obj, index) => {
                      return (
                        <>
                          <option value={obj.name}>{obj.name}</option>
                        </>
                      );
                    })}
                  </select>
                  {errors.country?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *Country is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="px-5">
                  <label for="inputState" className="form-label">
                    State*
                  </label>
                  <select
                    class="form-select border-0 inputBorder"
                    aria-label="Default select example"
                    id="inputState"
                    {...register("State", { required: true })}
                    onChange={(e) => handleSelect(e, "state")}
                  >
                    <option selected>Select</option>
                    {countryStatCity[indexes.state]?.states.map(
                      (obj, index) => {
                        return (
                          <>
                            <option value={obj.name}>{obj.name}</option>
                          </>
                        );
                      }
                    )}
                  </select>
                  {errors.State?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *State is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="px-5">
                  <label for="inputLocation" className="form-label">
                    Location*
                  </label>
                  <select
                    class="form-select border-0 inputBorder"
                    aria-label="Default select example"
                    id="inputLocation"
                    {...register("Location", { required: true })}
                  >
                    <option selected>Select</option>
                    {countryStatCity[indexes.state]?.states[
                      indexes.city
                    ]?.cities.map((obj, index) => {
                      return (
                        <>
                          <option value={obj.name}>{obj.name}</option>
                        </>
                      );
                    })}
                  </select>
                  {errors.Location?.type === "required" && (
                    <p role="alert" className="text-danger">
                      *Location is required
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-10  mx-auto mt-2 ">
        <div className="card   border-0 bg-transparent shadow cardRadi">
          <div className="card  border-0  p-0 text-center  ">
            <div className="card-body SubHeadings">
              <h3 className="card-title text-secondary">
                <strong>My Skills</strong>
              </h3>
            </div>
          </div>

          <form className="row g-3 p-4  text-secondary">
            <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputEducation" className="form-label">
                  Education*
                </label>
                <select
                  class="form-select border-0 inputBorder"
                  aria-label="Default select example"
                  id="inputEducation"
                  {...register("Education", { required: true })}
                >
                  <option selected>Select</option>
                  <option value="Under Graduate">Under Graduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Post Graduate">Post Graduate</option>
                </select>
                {errors.Education?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Education is required
                  </p>
                )}
              </div>
            </div>
            {/* <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputSpecialization" className="form-label">
                  Specialization*
                </label>
                <select
                  class="form-select border-0 inputBorder"
                  aria-label="Default select example"
                  id="inputSpecialization"
                  {...register("Specialization", { required: true })}
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                {errors.Specialization?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Specialization is required
                  </p>
                )}
              </div>
            </div> */}

            <div className="col-md-6 ">
              <div className="px-5">
                <label for="inputSkill" className="form-label">
                  Skill*
                </label>
                <select
                  class="form-select border-0 inputBorder"
                  aria-label="Default select example"
                  id="inputsSkill"
                  {...register("Skill", { required: true })}
                >
                  <option selected>select</option>
                  <option value="1">Java</option>
                  <option value="3">Java Script</option>
                  <option value="3">C++</option>
                  <option value="3">C#</option>
                  <option value="3">Python</option>
                  <option value="3">dotnet</option>
                  <option value="2">React js</option>
                  <option value="3">Next js</option>
                  <option value="3">Angular</option>
                  <option value="3">Node js</option>
                  <option value="3">Django</option>
                  <option value="3">Flask</option>
                  <option value="3">Magento</option>
                  <option value="3">Shopify</option>
                  <option value="3">Wordpress</option>
                  <option value="3">UX/UI</option>
                  <option value="3">AWS</option>
                  <option value="3">GCP</option>
                  <option value="3">Azure</option>
                </select>
                {errors.Skill?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Skill is required
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-10  mx-auto mt-2 ">
        <div className="card   border-0 bg-transparent shadow cardRadi">
          <div className="card  border-0  p-0 text-center  ">
            <div className="card-body SubHeadings">
              <h3 className="card-title text-secondary">
                <strong>My Experience</strong>
              </h3>
            </div>
          </div>

          <form className="row g-3 p-4  text-secondary">
            <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputCurrent_employere" className="form-label">
                  Current employer*
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-secondary border-0 inputBorder "
                  id="inputCurrent_employere"
                  {...register("Current_employere", { required: true })}
                />
                {errors.Current_employere?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Current_employere is required
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputCan_join_by" className="form-label">
                  Can join by*
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-secondary border-0 inputBorder "
                  id="inputCan_join_by"
                  {...register("Can_join_by", { required: true })}
                />
                {errors.Can_join_by?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Can join by is required
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputTotal_exeperience" className="form-label ">
                  Total exeperience (In Years)*
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-secondary border-0 inputBorder"
                  id="inputTotal_exeperience"
                  {...register("Total_exeperience", { required: true })}
                />
                {errors.Total_exeperience?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Total_exeperience is required
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputRelevant_Exeperience" className="form-label">
                  Relevant Exeperience (In Years)*
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-secondary border-0 inputBorder"
                  id="inputRelevant_Exeperience"
                  {...register(" Relevant_Exeperience", { required: true })}
                />
                {errors.Relevant_Exeperience?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Relevant_Exeperience is required
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="px-5">
                <label for="inputNotice_Period" className="form-label">
                  Notice Period (In Days)*
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-secondary border-0 inputBorder"
                  id="inputNotice_Period"
                  {...register("Notice_Period", { required: true })}
                />
                {errors.Notice_Period?.type === "required" && (
                  <p role="alert" className="text-danger">
                    *Notice_Period is required
                  </p>
                )}
              </div>
            </div>
            {/* <div className="col-md-6 mx-auto">
              <div className="px-5">
                <label for="inputvisa_dependent" className="form-label">
                  Are you visa dependent*
                </label>
                <select
                  class="form-select border-0 inputBorder"
                  aria-label="Default select example"
                  id="inputvisa_dependent"
                  {...register("visa_dependent", { required: true })}
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                {errors.visa_dependent?.type === "required" && (
                  <p role="alert" className="text-danger">
                    * This field is required
                  </p>
                )}
              </div>
            </div> */}
          </form>
        </div>
      </div>

      <div className="col-md-10 mx-auto text-center mt-2 ">
        <div className="card border-0 bg-transparent shadow cardRadi ">
          <div className="card-body text-ceneter SubHeadings">
            <h3 className="card-title  text-secondary">
              <strong>Review</strong>
            </h3>
            <div className="col-md-5 mx-auto">
              <div class="mb-3 mt-5">
                <label for="formFile" class="form-label">
                  <h4 className="text-secondary fw-bold">Uplaod Resume*</h4>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  {...register("Resume", { required: true })}
                />
                {errors.Resume?.type === "required" && (
                  <p role="alert" className="text-danger">
                    * Resume is required
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          type="button"
          class="btn btn-primary btn-lg rounded-pill mt-4"
          onClick={handleSubmit(submit)}
        >
          Submit
        </button>
      </div>

      <Modal
        className="position-absolute top-50 start-50 translate-middle"
        size={200}
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="card bg-transparent border-0">
          <div className="card-body">
            <img src={sendGif} alt="Sendgif" width={200} height={150} />
          </div>
        </div>
      </Modal>
    </>
  );
};
