import React from "react";
import clintCentric from "../Media/Images/ClientCentric.svg";
import serviceProducts from "../Media/Images/Serviceproducts.svg";
import { Caurousel } from "../components/Caurousel";
import proTcover1 from "../Media/Images/protcover1.svg";
import proTcover2 from "../Media/Images/proTcover2.svg";
import proTcover3 from "../Media/Images/ProTcover3.svg";
import strips from "../components/StripDesign";

export const ProTLabs = () => {
  return (
    <>
      <div className="fade-in-page overflowHidden">
        <div className="allMaringTop">
          <Caurousel
            image1={proTcover1}
            image2={proTcover2}
            image3={proTcover3}
          />
        </div>
        <div className="col-md-10 mx-auto animate-reveal animate-first">
          <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
            <div className="col">
              <div className="card cardStyle">
                <img src={clintCentric} />
                <div className="info">
                  <h1>CLIENT CENTRIC SERVICE MODELS</h1>
                </div>
              </div>
              <div className="mt-5 text-secondary">
                <div className="my-4">{strips.StripDesign1}</div>
                <h2>
                  <strong>CLIENT CENTRIC SERVICE MODELS</strong>
                </h2>
                <p>
                  IT has evolved and so did the Industries but do we have scope
                  for our IT Services to be further evolved? How often do the IT
                  service providers realize that they are still offering age old
                  service models to their clients!
                </p>

                <p>
                  ©Pro(T) Labs is continuously building newer client centric
                  service model focusing on client benefits which can bring at
                  least 20%-30% direct cost savings and further 5-10% indirect
                  cost savings while ensuring better quality and shortened
                  delivery timelines.
                </p>
              </div>
              <div>{strips.StripDesign2}</div>
            </div>
            <div className="col">
              <div className="card cardStyle">
                <img src={serviceProducts} />
                <div className="info">
                  <h1>SERVICE PRODUCTS</h1>
                </div>
              </div>
              <div className="mt-5 text-secondary">
                <div className="my-4">{strips.StripDesign1}</div>

                <h2>
                  <strong>SERVICE PRODUCTS</strong>
                </h2>

                <p>
                  IT has evolved and so did the Industries but do we have scope
                  for our IT Services to be further evolved? How often do the IT
                  service providers realize that they are still offering age old
                  service models to their clients!
                </p>

                <p>
                  ©Pro(T) Labs is continuously building newer client centric
                  service model focusing on client benefits which can bring at
                  least 20%-30% direct cost savings and further 5-10% indirect
                  cost savings while ensuring better quality and shortened
                  delivery timelines.
                </p>
              </div>
              <div className="mt-5">{strips.StripDesign2}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
