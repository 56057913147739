import React from "react";
import image from "../Media/Images/GuidewirePageCover.svg";
import { Caurousel } from "../components/Caurousel";
import strips from "../components/StripDesign";
export const GuidewireServices = () => {
  return (
    <>
    <div className="fade-in-page overflowHidden">
      <section>
        <div className="allMaringTop">
          <Caurousel image1={image} image2={image} image3={image} />
        </div>
      </section>
      {/* <section className="overflowHidden">
        <div className="mt-5">
          <div className="card border-0">
            <div className="row pe-0 me-0">
              <div className="col-md-1 ms-auto">
                <div className="float-end my-5">{strips.StripDesign4}</div>
              </div>
              <div className="col-md-10">
                <div className="card border-0">
                  <div className="card-body text-center">
                    <h4 className="fw-bold" >
                      NICHE GUIDEWIRE SERVICES <br />
                      'THE GOOD' IS CONTINUOUSLY DELVIERED HERE
                    </h4>
                    <p>
                      Protecons is a niche Guidewire specialist services
                      provider with a fast growing team of ProtecoNiNs
                      (Guidewire experts). People are @core of our philosophy
                      which helps us keep happy clients, end consumers, partners
                      and employees. Our undivided focus on Guidewire ecosystem
                      and on People allows us to deliver ‘The Good’ quality
                      services consistently. ProtecoNINs brings in their deep
                      Guidewire experience to provide range of services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <div className="float-start my-5">{strips.StripDesign5}</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="text-center">{strips.StripDesign3}</div>
      </section>

      <section>
        <div className="container mt-5 ">
          <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col ">
              <div class="card border-0 shadow CardFixedHight1 guidewireCard1 card-hover cardRadi" data-aos="fade-right"  data-aos-delay="100">
                <div class="card-body text-center marginTopHddings text-white">
                  <h4 className="fw-bold">On Demand Services</h4>
                  <p>
                    Niche Staffing and Consulting services at nearshore and
                    Offshore locations
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card border-0 shadow CardFixedHight1 guidewireCard2 card-hover cardRadi " data-aos="fade-down"  data-aos-delay="100">
                <div class="card-body text-center marginTopHddings text-white">
                  <h4 className="fw-bold">Quality Assurance Services</h4>
                  <p>Manual and automation testing offerings.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card border-0 shadow CardFixedHight1 guidewireCard3 card-hover cardRadi" data-aos="fade-left">
                <div class="card-body text-center marginTopHddings text-white">
                  <h4 className="fw-bold">Upgrade Services</h4>
                  <p>Upgrade Assessment and Implementations services</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card border-0 shadow CardFixedHight1 guidewireCard4 card-hover cardRadi" data-aos="fade-up">
                <div class="card-body text-center marginTopHddings text-white">
                  <h4 className="fw-bold">Data Services</h4>
                  <p>Data Migration, DataHub and InfoCenter experts</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="overflowHidden">
      <div class="counter-area bg-with-black " style={{ marginTop: '50px' }}>
        <div class="container ">
          <div class="row ">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12 col-12">
              <div class="section-title">
                <h6 className="fw-bold text-white">Guidewire Workforce Experience Overview</h6>
                <h2>THE GOOD IS CONTINUOUSLY DELIVERED HERE</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="all-counter">
                <div class="counter-box">
                  <h2 class="counter counter-up" data-counterup-time="1500" data-counterup-delay="30">30000</h2><span class="plus">+</span>
                  <p class="text">MDs</p>
                </div>
                <div class="counter-box">
                  <h2 class="counter counter-up" data-counterup-time="1500" data-counterup-delay="30">40</h2><span class="plus">+</span>
                  <p class="text">GW Projects</p>
                </div>
                <div class="counter-box">
                  <h2 class="counter"><span class="counter counter-up" data-counterup-time="1500" data-counterup-delay="30">100</span>%</h2>
                  <p class="text">Certified Resources</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
      </div>
    </>
  );
};
