import React, { useEffect, useState } from "react";
import strips from "../components/StripDesign";

export const SkillsTraining = () => {
  const [titleText, setTitleText] = useState("");

  useEffect(() => {
    const title = "IT and Computer Training for Government & Private Sectors";
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (title.length > currentIndex) {
        setTitleText(title.substring(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 200);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="fade-in-page">
        <section>
          <div className="allMaringTop">
            <div className="row pe-0 me-0 overflowHidden">
              <div className="skill_section d-flex flex-column justify-content-center">
                <div className="black_overlay"></div>
                <div className="position-relative">
                  <div className="text-center">
                    <div className="px-5 mx-5">
                      <h1>{titleText}</h1>
                    </div>
                    <p className="mt-4 paragraph_style">
                      At Protecons Solutions Private Limited, we offer specialized IT and computer
                      training programs designed to empower professionals
                      across both government and private sectors. Our programs
                      equip employees with the skills to leverage technology,
                      enhance efficiency, and ensure secure, data-driven
                      decision-making, fostering innovation and productivity
                      in their respective organizations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="animate-reveal animate-first">
            <div className="traning_program_section">
              <div className="my-4">{strips.StripDesign1}</div>
              <h2>
                <strong>Comprehensive Training Programs</strong>
              </h2>
              <p>
                We provide tailor-made training programs to address the unique
                needs of both sectors, ensuring that each organization can
                meet its technological goals and improve its operational
                efficiency. Our training modules are carefully designed to
                cover a wide range of IT skills, from basic computer literacy
                to advanced data management, cybersecurity, and cloud
                technologies.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="animate-reveal animate-first">
            <div className="goverment_section">
              <div className="">
                <div className="my-4">{strips.StripDesign1}</div>
                <h2>
                  <strong>Government Sector Training</strong>
                </h2>
                <p>
                  Our government sector training programs are crafted to support
                  digital transformation initiatives, helping public sector
                  employees adopt and implement technology solutions that
                  improve governance and service delivery.
                </p>
                <div className="mt-5">
                  <h4>
                    <strong>Key Training Areas:</strong>
                  </h4>
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <ul className="nested">
                          <li>
                            <h5 className="training_areas_title">e-Governance Solutions</h5>
                            <ul>
                              <li>
                                Training on e-Governance platforms to enhance public
                                services.
                              </li>
                              <li>
                                Utilization of digital tools for document
                                management, automation, and streamlined workflows.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className="training_areas_title">Cybersecurity for Public Services</h5>
                            <ul>
                              <li>
                                Comprehensive training in data privacy, threat
                                detection, and mitigation strategies..
                              </li>
                              <li>
                                Ensuring compliance with government data protection
                                standards..
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className="training_areas_title">IT Tools for Administrative Efficiency</h5>
                            <ul>
                              <li>
                                Use of Microsoft Office Suite, cloud services, and
                                enterprise resource planning (ERP) solutions.
                              </li>
                              <li>
                                Introduction to government-approved IT frameworks
                                and protocols.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <ul className="nested">
                          <li>
                            <h5 className="training_areas_title">Data Management and Analytics</h5>
                            <ul>
                              <li>
                                Introduction to database management systems for
                                secure information storage.
                              </li>
                              <li>
                                Data-driven decision-making through analytics and
                                reporting tools.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className="training_areas_title">Networking and Infrastructure</h5>
                            <ul>
                              <li>
                                Building and managing secure government networks and
                                IT infrastructure.
                              </li>
                              <li>
                                Troubleshooting techniques for smooth, uninterrupted
                                public services.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="animate-reveal animate-first">
            <div className="private_section">
              <div>
                <div className="my-4">{strips.StripDesign1}</div>
                <h2>
                  <strong className="text-white">Private Sector Training</strong>
                </h2>
                <p className="text-light">
                  Our private sector training focuses on improving
                  competitiveness by enhancing digital literacy, enabling the
                  adoption of cutting-edge technologies, and supporting secure
                  IT operations that align with industry trends.
                </p>
                <div className="mt-5">
                  <h5>
                    <strong className="text-white">Key Training Areas:</strong>
                  </h5>
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <ul className="nested nested_key_points">
                          <li>
                            <h5>Digital Transformation in Business</h5>
                            <ul>
                              <li>
                                Training on implementing digital strategies,
                                automating workflows, and optimizing business
                                operations using IT tools.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5>Cloud Computing and Data Storage</h5>
                            <ul>
                              <li>
                                Introduction to cloud platforms such as AWS, Google
                                Cloud, and Microsoft Azure.
                              </li>
                              <li>
                                Securing data storage solutions and ensuring
                                compliance with industry regulations.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5>Cybersecurity and Data Protection</h5>
                            <ul>
                              <li>
                                Training employees on cybersecurity best practices,
                                including encryption, secure data handling, and
                                malware protection.
                              </li>
                              <li>
                                Conducting risk assessments and implementing
                                proactive measures to protect private-sector
                                companies from cyber threats.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <ul className="nested_key_points">
                          <li>
                            <h5>Advanced IT Skills for Business Growth</h5>
                            <ul className="nested">
                              <li>
                                Developing competencies in programming, software
                                development, and database management.
                              </li>
                              <li>
                                Networking, server management, and IT infrastructure
                                optimization.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5>Project Management and Collaboration Tools</h5>
                            <ul>
                              <li>
                                Training on digital project management tools such as
                                Microsoft Teams, Jira, and Asana for enhanced
                                collaboration.
                              </li>
                              <li>
                                Integrating communication tools for remote work,
                                hybrid models, and global collaboration.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="benifit_program_section">
            <div className="my-4">{strips.StripDesign1}</div>
            <h2>
              <strong>Benefits of Our Training Programs</strong>
            </h2>
            <div className="mt-4">
              <div className="">
                <ul className="nested">
                  <li>
                    <h5>Increased Efficiency</h5>
                    <ul>
                      <li>
                        Employees will be able to work more efficiently by
                        leveraging the latest IT tools and systems,
                        minimizing errors and increasing productivity.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Enhanced Security</h5>
                    <ul>
                      <li>
                        Both sectors will benefit from a deep understanding
                        of cybersecurity protocols, ensuring sensitive data
                        is protected against evolving cyber threats.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Cost Savings :</h5>
                    <ul>
                      <li>
                        With better use of technology and automation,
                        organizations can reduce operational costs and
                        resource wastage.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Scalability :</h5>
                    <ul>
                      <li>
                        Our training programs prepare employees to handle
                        scalable solutions, adapting to future technological
                        advancements seamlessly.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="enroll_section">
            <div className="my-4">{strips.StripDesign1}</div>
            <h2>
              <strong>Who Should Enroll?</strong>
            </h2>
            <div className="mt-4">
              <h5>Our training programs are designed for:</h5>
              <ul className="normal mt-3">
                <li>
                  Government employees involved in public service
                  delivery, e-Governance, and administration.
                </li>
                <li>
                  Private sector professionals looking to improve their IT
                  skills for business development, operations, and
                  security.
                </li>
                <li>
                  IT teams managing networks, infrastructure, and
                  cybersecurity in both sectors.
                </li>
                <li>
                  Executives and decision-makers who want to drive digital
                  transformation within their organizations.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section>
          <div className="why_choose_section">
            <div>
              <div className="my-4">{strips.StripDesign1}</div>

              <h2>
                <strong>Why Choose Us?</strong>
              </h2>

              <div className="mt-4">
                <ul className="nested">
                  <li>
                    <h5>Customized Programs :</h5>
                    <ul>
                      <li>
                        We tailor our training content to meet the specific
                        needs of your organization and industry.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Expert Trainers :</h5>
                    <ul>
                      <li>
                        Learn from certified professionals with vast
                        experience in both government and private sector IT.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Hands-On Learning :</h5>
                    <ul>
                      <li>
                        Our programs focus on practical, project-based
                        learning, allowing participants to apply what
                        they’ve learned directly to their roles.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Ongoing Support :</h5>
                    <ul>
                      <li>
                        After the training, we offer continued support to
                        ensure that employees can implement the skills
                        learned effectively.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-2">{strips.StripDesign2}</div>
          </div>
        </section>


      </div>
    </>
  );
};
