import React from 'react'

export const TestimoniesCard = ({ data }) => {
    console.log("^&**********" , data)
    return (
        <>
            {data?.id % 2 === 0 ?
                <>
                    <div className='row mt-5'>
                        <div className='col-md-3 '>
                            <div className='card border-0 bg-transparent '>
                                <div className=' ms-auto p-4'>
                                    <img src={data?.image} class="img-fluid rounded-circle" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9 '>
                            <div className='card border-0 bg-transparent shadow cardRadi'>
                                <div className='card-body p-4'>
                                    <p className='text-secondary'>
                                        {data?.desc}
                                    </p>
                                    <h4 className='fw-bold text-secondary'>
                                        {data?.name}
                                    </h4>
                                    <p className='text-secondary'>{data?.designation}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div className='row'>
                        <div className='col-md-9 '>
                            <div className='card border-0 bg-transparent shadow cardRadi'>
                                <div className='card-body p-4'>
                                    <p className='text-secondary'>
                                        {data.desc}
                                    </p>
                                    <div className='ms-auto'>
                                        <h4 className='fw-bold text-secondary'>
                                            {data.name}
                                        </h4>
                                        <p className='text-secondary'>{data?.designation}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3  mb-5'>
                            <div className='card border-0 bg-transparent'>
                                <div className='p-4'>
                                    <img src={data?.image} class="img-fluid rounded-circle" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
