import React from 'react'
import accenture from '../Media/Images/ClintsLogo/accenture.png'
import lti from '../Media/Images/ClintsLogo/LTIMindtree.png'
import wipro from '../Media/Images/ClintsLogo/wipro.png'
import ncdex from '../Media/Images/ClintsLogo/ncdex.png'
import guidewire from '../Media/Images/ClintsLogo/guidewire.png'

export const ClientsCard = () => {
	return (
		<>
			<div className='col-md-11 mx-auto mt-5'>
				<div className="marquee-box marqueeBoxEaseOut ">
					<div className="marquee">
						<figure>
							<img src={guidewire} alt="featured creator" />
							<figcaption>Sanjeev kapoor Academy</figcaption>
						</figure>
						<figure>
							<img src={accenture} alt="featured creator" />
							<figcaption>Tuition Fe by BSE</figcaption>
						</figure>
						<figure>
							<img src={wipro} alt="featured creator" />
							<figcaption>Symboisis Centre For <br />Entrepreneurship & Innovation</figcaption>
						</figure>
						<figure>
							<img src={lti} alt="featured creator" />
							<figcaption>Shikshamaitri</figcaption>
						</figure>
						<figure>
							<img src={ncdex} alt="featured creator" />
							<figcaption>Sanjeev kapoor Academy</figcaption>
						</figure>
					</div>
					<div className="marquee">
						<figure>
							<img src={guidewire} alt="featured creator" />
							<figcaption>Sanjeev kapoor Academy</figcaption>
						</figure>
						<figure>
							<img src={accenture} alt="featured creator" />
							<figcaption>Tuition Fe by BSE</figcaption>
						</figure>
						<figure>
							<img src={wipro} alt="featured creator" />
							<figcaption>Symboisis Centre For <br />Entrepreneurship & Innovation</figcaption>
						</figure>
						<figure>
							<img src={lti} alt="featured creator" />
							<figcaption>Shikshamaitri</figcaption>
						</figure>
						<figure>
							<img src={ncdex} alt="featured creator" />
							<figcaption>Sanjeev kapoor Academy</figcaption>
						</figure>
					</div>
				</div>
			</div>
		</>
	)
}



