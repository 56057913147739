import React from "react";
import gptw from "../Media/Images/GPTW.svg";
import strips from "../components/StripDesign";
import owner from "../Media/Images/CEO.svg";
import { ImageCard } from "../components/ImageCard";
import HR from "../Media/Images/tanu.svg";
export const Gptw = () => {
  return (
    <>
      <div className="fade-in-page">
        <div class="card mb-3 border-0 allMaringTop gptwCoverBg">
          <div className="card-body p-5 ">
            <img src={gptw} class="card-img-top p-5  shadow" alt="..." />
          </div>
        </div>

        <section>
          <div className="mt-5">
            <div className="card border-0">
              <div className="row pe-0 me-0 overflowHidden">
                <div className="col-md-1 ms-auto">
                  <div className="float-end my-2">{strips.StripDesign4}</div>
                </div>
                <div className="col-md-10">
                  <div className="card border-0">
                    <div className="card-body text-center">
                      <p className="text-secondary fs-5">
                        Bareilly, Uttar Pradesh – April 16, 2023 – Protecons
                        Solutions Pvt Ltd (Protecons) today announces that it has
                        earned certification as a 2023 Great Place to Work®
                        based on local employees’ testaments noting remote work
                        opportunities, engaged teams and company culture.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="float-start my-2">{strips.StripDesign5}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center">{strips.StripDesign3}</div>
          <div className="col-md-11 mx-auto">
            <div className="card border-0 mt-5 cardBottomRadi gptwTostmasterGb shadow">
              <div className="card-body p-5">
                <div className="card text-center border-0  bg-transparent">
                  <div className="card-body text-white">
                    <h1 className="card-title ">
                      Empowering Excellence: Protecons' Employee Engagement
                      Initiatives
                    </h1>
                    <div className="col-md-6 mx-auto mt-4">
                      <p className="card-text ">
                        Protecons has implemented various employee programs
                        dedicated to team building, communication and community.
                        Protecons creative virtual spaces bring employees
                        together under shared similar interests, like ‘Kreate
                        club' for bringing synergy to creative energies and,
                        'Toastmasters Club' for honing public
                        speaking/communication skills. In addition, we have an
                        accolade award program that enables all employees to
                        nominate their teams or colleagues for exceptional
                        performance, celebrating their contributions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="conatiner-fluid mt-5 overflowHidden">
            <div className="row p-0 m-0 ">
              <div className="col-lg-4 ps-0 ms-0 mt-2">
                <div className="card border-0 cardBottomRadi-2 ">
                  <div className="card-body">
                    <div className="col-md-8 mx-auto mt-4">
                      <ImageCard image={owner} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 ms-auto pe-0 me-0 mt-4">
                <div
                  className="card border-0 shadow bg-light cardBottomRadi-3 mb-4"
                  data-aos="fade-left"
                >
                  <div className="card-body m-5">
                      <p className="text-secondary fs-5 text-wrap ">
                        I have the utmost appreciation for our employees who
                        helped us achieve the Great Place to Work certification
                        in the region. The honest employee feedback, meaningful
                        engagement programs, and the hard work ethic to be the
                        change makers while upholding the company values, are
                        all key,” said Hitesh Kumar, Chief Executive Officer,
                        Protecons.
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="mt-5">
            <div className="card border-0">
              <div className="row p-0 m-0 overflowHidden">
                <div className="col-md-1">
                  <div className="float-end my-2">{strips.StripDesign4}</div>
                </div>
                <div className="col-md-10">
                  <div className="card border-0">
                    <div className="card-body text-center mt-5">
                      <p className="text-secondary fs-5">
                        “Protecons will continue to value all input that
                        transforms our business into a more cohesive, fun-filled
                        and innovative workplace for employees and clients
                        alike.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="float-start my-2">{strips.StripDesign5}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="conatiner-fluid mt-5 ">
            <div className="row p-0 m-0">
              <div className="col-lg-8 mt-2 ps-0">
                <div
                  className="card border-0 shadow bg-light cardBottomRadi-2 pe-0"
                  data-aos="fade-right"
                >
                  <div className="card-body m-5">
                    <p className="text-secondary fs-5">
                      “Truly a remarkable moment for all of us. Protecons
                      certified as a Great Place To Work simply reminds us how
                      we stay ahead of the curve in prioritizing the people
                      agenda. In this accomplishment we share appreciation
                      across the board, including all our leaders, people
                      managers, GPTW ambassadors, and human resource teams for
                      their collective efforts that brought home this globally
                      acclaimed recognition," said Tanu Mishra, Director, Human
                      Resources & Operations, Protecons.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-2">
                <div className="card border-0 cardBottomRadi-3">
                  <div className="card-body">
                    <div className="col-lg-8 mx-auto mt-5">
                      <ImageCard image={HR} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
